import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const Navbar = () => {
  const [isSticky, setSticky] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const prevLocationRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // Scroll to top if location changes
    if (location !== prevLocationRef.current) {
      window.scrollTo(0, 0);
      prevLocationRef.current = location;
    }
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  // const scrollWidthOffset = (el) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //   const yOffset = -120;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  // };

  return (
    <>
      <nav
        className={`${isSticky ? "sticky" : ""} ${
          isMenuOpen ? "menu-open" : ""
        }`}
      >
        <Link to="/">
          <div className="nav-logo">
            <img src="../assets/images/logo.svg" alt="" />
          </div>
        </Link>
        <div className={`pages ${isMenuOpen ? "open" : ""}`}>
          {/* <a href="/" className={isActive('/')} onClick={handleLinkClick}>Home</a>
          <a href="/about" className={isActive('/about')} onClick={handleLinkClick}>About Us</a>
          <a href="/message" className={isActive('/message')} onClick={handleLinkClick}>Message</a>
          <a href="/contact" className={isActive('/contact')} onClick={handleLinkClick}>Contact</a> */}
          <Link to="/" className={isActive("/")} onClick={handleLinkClick}>
            Home
          </Link>
          <Link
            to="/about"
            className={isActive("/about")}
            onClick={handleLinkClick}
          >
            About Us
          </Link>
          <Link
            to="/message"
            className={isActive("/message")}
            onClick={handleLinkClick}
          >
            Message
          </Link>
          <NavHashLink
            // scroll={scrollWidthOffset}
            smooth={true}
            to="/about#ourCompanies" onClick={handleLinkClick}
          >
            Our Companies
          </NavHashLink>
          <Link
            to="/contact"
            className={isActive("/contact")}
            onClick={toggleMenu}
          >
            Contact
          </Link>
        </div>
        <input
          type="checkbox"
          id="check"
          className="checkbtn"
          checked={isMenuOpen}
          onChange={toggleMenu}
        />
        <label htmlFor="check" className="checkbtn-label">
          <i className="fa-solid fa-bars"></i>
        </label>
      </nav>
    </>
  );
};

export default Navbar;
