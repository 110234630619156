import React from "react";
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Footer = () => {
  const subscribe = (e) => {
    e.preventDefault()
    const data = {
      email: e.target.email.value,
    }

    emailjs.send('service_20softed80', 'template_ddr2jj9', { ...data }, 'g6TTPvGjSH4hRDq9k').then(
      result => {
        console.log(result.text);
        toast.success('Thank you for subscribing!');
      },
      error => {
        console.log(error.text);
        toast.error('Failed to subscribe, please try again.');
      }
    )
    e.target.reset()
  }
  return (
    <>
      <footer>
        <div className="footer-up">
          <a href="/">
            <div className="footer-logo">
              <img src="../assets/images/logo.svg" alt="logo" />
            </div>
          </a>

          <div className="site-map">
            <h4>Sitemap</h4>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/message">Message</Link>
            <Link to="/contact">Contact</Link>
          </div>
          <div className="socials">
            <h4>Socials</h4>
            <a
              href="https://www.facebook.com/softedgroup"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://np.linkedin.com/company/softed-group"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
            <a
              href="https://www.instagram.com/softedgroup/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            {/* <a href="/">Twitter</a> */}
          </div>

          <div className="office">
            <h4>Head Office</h4>
            <p>Lalupate Marga, Putalisadak Height, Kathmandu, Nepal</p>
            <h4 id="news-letter">
              News Letter
              <form onSubmit={subscribe} id="subscribeForm">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  required
                />
                <button type="submit" name="submit" value="" >
                  {/* <img src="../assets/icons/mail.svg" alt="" /> */}
                  SUBSCRIBE
                </button>
              </form>
            </h4>
          </div>
        </div>
        <div className="footer-buttom">
          <div className="footer-buttom-link">
            <a href="mailto:info@softedgroup.com">info@softedgroup.com</a>
          </div>
          <div className="footer-buttom-link">
            <a href="tel:14523759">(+977)1 452-3759</a>
          </div>
          <p>© 2024 L2024 - SoftED Group. All rights reserved.</p>
        </div>
      </footer>
      <ToastContainer />
    </>
  );
};
export default Footer;
