const Message = () => {
  return (
    <>
      <section className="message">
        <div className="about-icon">
        <a href="/"><i class="fa-solid fa-house"></i></a>
          <i class="fa-solid fa-angle-right"></i>
          <p>Chairman's Message</p>
        </div>
        <div className="element7">

        <img src="../assets/images/lines.png" alt="" />
        </div>
        <h1>Chairman's Message</h1>

        <div className="message-container">
          <div className="message-container-img">
            <img src="../assets/images/chairman.png" alt="" />
            <h3 style={{marginTop:'10px'}}>Bigyan Shrestha</h3>
            <p>Chairman</p>
          </div>
          <div className="message-container-text">
            <p>
              With a new constitution, Nepal has entered into a new era of
              opportunity with many job opportunities where the younger
              generation requires industry-focused skills in technology. There
              are several job prospects in technology, and Softed Group intends
              to work in IT education and technology to aid in the country’s
              general development.
              <br />
              <br />
              Softed Group also has a subdomain “Softed Business”, which is also
              entering into various business domains such as tourism,
              healthcare, and e-commerce, a major foundation for
              nation-building, creating a lot of opportunities for the country’s
              youth.
              <br /> <br />
              Currently, Softed Group has more than 500+ team members working
              under Softed Group companies and has provided immense services and
              created business and job opportunities for our team members. I am
              confident our group will grow more in the coming future, adding
              more bricks to the development of the nation.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default Message;
