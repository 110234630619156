import React from "react";
import emailjs from 'emailjs-com'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault()
    const data = {
      name: e.target.name.value,
      email: e.target.email.value,
      subject: e.target.subject.value,
      website: e.target.website.value,
      message: e.target.message.value,
    }

    emailjs.send('service_20softed80', 'template_u6zp8za', { ...data }, 'g6TTPvGjSH4hRDq9k').then(
      result => {
        console.log(result.text);
        toast.success('Message sent successfully!');
      },
      error => {
        console.log(error.text);
        toast.error('Failed to send the message, please try again.');
      }
    )
    e.target.reset()
  }
  return (
    <>
      <section>
        <div className="element8">
          <img src="../assets/images/element8.png" alt="" />
        </div>
        <div className="contact">
          <div className="about-icon">
            <a href="/">
              <i class="fa-solid fa-house"></i>
            </a>
            <i class="fa-solid fa-angle-right"></i>
            <p>Contact</p>
          </div>
          <div className="element7">
            <img src="../assets/images/lines.png" alt="" />
          </div>
          <h2>Contact Us</h2>

          <div className="contact-container">
            <div className="contact-info">
              <h3>Get in touch</h3>
              <div className="contact-info-details">
                <div className="info-detail">
                  <div className="info-detail-icon">
                    <img src="../assets/icons/envelope.svg " alt="" />
                  </div>
                  <div className="info-detail-text">
                    <h4>EMAIL US</h4>
                    <a href="mailto:info@softedgroup.com">
                      info@softedgroup.com
                    </a>{" "}
{/*                     <br />
                    <a href="mailto:bigyan@softedgroup.com">
                      bigyan@softedgroup.com
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="contact-info-details">
                <div className="info-detail">
                  <div className="info-detail-icon">
                    <img src="../assets/icons/phone.svg " alt="" />
                  </div>
                  <div className="info-detail-text">
                    <h4>PHONE NUMBER</h4>
                    <a href="tel:1 441 8019">(+977)1 441 8019, 452 3759</a>
                  </div>
                </div>
              </div>
              <div className="contact-info-details">
                <div className="info-detail">
                  <div className="info-detail-icon">
                    <img src="../assets/icons/location.svg " alt="" />
                  </div>
                  <div className="info-detail-text">
                    <p>Lalupate Marga, Putalisadak Height, Kathmandu, Nepal</p>
                  </div>
                </div>
              </div>

              <div className="contact-info-social">
                <div className="info-social-text">
                  <img src="../assets/icons/line.svg" alt="" />
                  <p>Connect with us:</p>
                </div>
              </div>
              <div className="info-social-icons">
                <a
                  href="https://www.facebook.com/softedgroup"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../assets/icons/facebook.svg" alt="" />
                </a>
                <a
                  href="https://np.linkedin.com/company/softed-group"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../assets/icons/linkedin.svg" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/softedgroup/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../assets/icons/instagram.svg" alt="" />
                </a>
                {/* <a href="/">
                <img src="../assets/icons/twitter.svg" alt="" />
              </a> */}
              </div>
            </div>

            <div className="contact-form">
              <form action="#" method="POST" onSubmit={sendEmail}>
                <div class="form-content">
                  <div class="form-name">
                    <label for="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      required
                    />
                  </div>

                  <div class="form-email">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                  <div class="form-subject">
                    <label for="subject">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div class="form-website">
                    <label for="website">Website</label>
                    <input
                      type="text"
                      name="website"
                      id="website"
                      placeholder="website"
                    />
                  </div>

                  <div class="form-message">
                    <label for="message">Message</label>
                    <textarea
                      type="text"
                      name="message"
                      id="message"
                      placeholder="Your Message"
                      rows={8}
                      required
                    />
                  </div>

                  <button type="submit" name="submit">
                    Send Message <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};
export default Contact;
