import React, { useEffect, useRef } from "react";

const Home = () => {
  const scrollRef = useRef(null);
  const scrollTimeoutRef = useRef(null);
  const startX = useRef(null);
  const scrollLeft = useRef(null);

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints; 
  };

  const getEventPageX = (event) => {
    if (isTouchDevice() && event.touches) {
      return event.touches[0].pageX;
    } else {
      return event.pageX;
    }
  };

  const handleStart = (event) => {
    const container = scrollRef.current;
    if (!container) return;
    const pageX = getEventPageX(event);
    startX.current = pageX - container.offsetLeft;
    scrollLeft.current = container.scrollLeft;
    container.style.cursor = 'grabbing';
  };

  const handleMove = (event) => {
    const container = scrollRef.current;
    if (!startX.current || !container) return;
    event.preventDefault();
    const pageX = getEventPageX(event);
    const x = pageX - container.offsetLeft;
    const walk = (x - startX.current) * 1.5;
    container.scrollLeft = scrollLeft.current - walk;
  };

  const handleEnd = () => {
    const container = scrollRef.current;
    if (!container) return;
    startX.current = null;
    scrollLeft.current = null;
    container.style.cursor = 'grab';
  };

  const handleScroll = (direction) => {
    const container = scrollRef.current;
    const cardWidth = 230;
    const scrollAmount = direction === "left" ? -cardWidth : cardWidth;

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      container.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }, 100);
  };

  useEffect(() => {
    const container = scrollRef.current;
    container.scrollTo({
      left: container.scrollLeft,
      behavior: "smooth",
    });

    return () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);

  const networklist = [
    
    {
      logo: "../assets/images/softwarica_logo.svg",
      link: "https://www.softwarica.edu.np/",
    },
    {
      logo: "../assets/images/sunway_logo.svg",
      link: "https://sunway.edu.np/",
    },
    {
      logo: "../assets/images/unicampus_logo.svg",
      link: "https://www.myunicampus.com/site/index.php",
    },
    {
      logo: "../assets/images/ukcolleges_logo.svg",
      link: "https://theukcolleges.com/",
    },
    {
      logo: "../assets/images/digimedical_logo.svg",
      link: "https://app.digimedicalsewa.com/",
    },
    {
      logo: "../assets/images/digischool_logo.svg",
      link: "https://digischoolglobal.com/",
    },
    {
      logo: "../assets/images/digitechnology_logo.svg",
      link: "https://digitechnologynepal.com/",
    },
    {
      logo: "../assets/images/digiworld_logo.svg",
      link: "https://digiworld.ltd/",
    },

  ]

  return (
    <>
      <section>
      <div className="element1">
        <img src="../assets/images/element1.png" alt="" />
      </div>
      <div className="home">
        <div className="home-content">
          <h2>
            Navigating The Rapids Of Soft<span>ED</span>
          </h2>
          <p>
            At Softed, we transcend the boundaries of conventional education and
            technology, pioneering a journey that began with the goal of
            educating people about technology and evolved into a dynamic force
            shaping the future of education through cutting-edge technological
            solutions.
          </p>
          <button>
            <a href="/contact">Contact Us</a>
          </button>
        </div>
        <div className="home-cubes">
        {/* <video loop muted autoPlay playsInline onEnded={() => this.videoRef.current.load()}>
          <source src={`${process.env.PUBLIC_URL}/assets/images/block.mp4`} type="video/mp4" />
        </video> */}
        {/* <video src="../assets/images/block.mp4" autoPlay loop></video> */}
          <img src="../assets/images/block.gif" alt="blocks" />
        </div>
        </div>
      </section>
      
      <section className="network">
        <div className="network-title">
          <div className="network-text">
            {/* <p>OUR</p> */}
            <p id="network-text-back">OUR <span>NETWORK</span></p>
            <p id="network-text-italic">YOUR SUCCESS</p>
          </div>
          <div className="network-arrow">
            <i
              className="fa-sharp fa-solid fa-arrow-left"
              id="network-arrow-left"
              onClick={() => handleScroll("left")}
            ></i>
            <i
              className="fa-sharp fa-solid fa-arrow-right"
              onClick={() => handleScroll("right")}
            ></i>
          </div>
        </div>
        <div
          className="network-container"
          ref={scrollRef}
          onMouseDown={handleStart}
          onMouseMove={handleMove}
          onMouseUp={handleEnd}
          onMouseLeave={handleEnd}
          onTouchStart={handleStart}
          onTouchMove={handleMove}
          onTouchEnd={handleEnd}
        >
           {networklist.map((network, index) => (
            <div className="network-card" key={index}>
              <a href={network.link} target="_blank" rel="noopener noreferrer">
                <img src={network.logo} alt="logo" />
              </a>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="objectives">
        <div className="objective-container">
          <div className="objective-image">
            <img src="../assets/images/obj_img1.png" alt="Objective 1" />
          </div>
          <div className="objective-text">
            <h2>Observe</h2>
            <p>
              We observe the needs of society through different thoughtful
              dimensions, enabling them with the needs of 21st century and make
              their life easier in terms of education, lifestyle, health and
              technology.
            </p>
          </div>
        </div>
        <div className="objective-container  objective-reversed">
          <div className="objective-text">
            <h2>Innovate</h2>
            <p>
              We aspire to develop innovative inventions for society that
              support creativity everywhere, and we believe that innovation is
              the key to success.
            </p>
          </div>
          <div className="objective-image">
            <img src="../assets/images/obj_img2.png" alt="Objective 1" />
          </div>
        </div>
        <div className="objective-container">
          <div className="objective-image">
            <img src="../assets/images/obj_img3.png" alt="Objective 1" />
          </div>
          <div className="objective-text">
            <h2>Deliver</h2>
            <p>
              We deliver you with the greatest service that our team has to
              offer inside the numerous initiatives that are dedicated to client
              satisfaction.
            </p>
          </div>
        </div>
        </div>
        <div className="element2">
          <img src="../assets/images/element2.png" alt="" />
        </div>
      </section>
      <section className="home-box ">
        <div className="home-box-img">
          <div className="home-box-img1">
            <img src="../assets/images/box_img.svg" alt="Box" />
          </div>
        </div>
        <div className="home-box-text">
          <h2>Make your seats booked for better tomorrow.</h2>
          <p>Subscribe to our Newsletter and stay updated!</p>
          <a href="#subscribeForm">SUBSCRIBE NOW</a>
        <div className="element3">
          <img src="../assets/images/element3.png" alt="" />
        </div>
        </div>
      </section>
      <section className="home-help">
        <h2>
          HOW WE CAN <span>HELP</span>
          <i>YOU</i>
        </h2>
        <div className="home-help-box">
          <div className="help-box box1">
            <img src="../assets/icons/career.svg" alt="Career" />
            <h3>Choosing Career Path</h3>
            <p>
              We have a various course and institution choice accessible for you
              to browse from where we can lead your career in right direction.
              From international degree to international destination you can get
              in one roof.
            </p>
          </div>
          <div className="help-box box2">
            <img src="../assets/icons/world.svg" alt="World" />
            <h3>Getting ready for Intl Course</h3>
            <p>
              Getting international education in the right time, depending on
              both your long-term goals and restless energy to dive into both a
              new world of academia and a new culture is fulfilled with any of
              our institution.
            </p>
          </div>
          <div className="help-box box3">
            <img src="../assets/icons/bridging.svg" alt="Bridging" />
            <h3>Bridging Student and IT</h3>
            <p>
              Through our creativity and excellence in education, we bring the
              possibilities of the digital age to the real world, bringing
              changes to people's lives.
            </p>
          </div>
          <div className="help-box box4">
            <img src="../assets/icons/service.svg" alt="Service" />
            <h3>24/7 Service</h3>
            <p>
              Services like peer mentoring programs, academic support, job
              placement services, clubs and societies, counselling and medical
              services is open 24/7 in each of our institution.
            </p>
          </div>
          <div className="help-box box5">
            <img src="../assets/icons/assessment.svg" alt="assessment" />
            <h3>Online Assessment</h3>
            <p>
              With our institution aspirant student will be getting online
              assessment which is easy to navigate.
            </p>
          </div>
          <div className="help-box box6">
            <img src="../assets/icons/collaboration.svg" alt="collaboration" />
            <h3>Encouraging Collaboration</h3>
            <p>
              It can be difficult to know where to draw the line, although a
              sense of competitiveness can be healthy, which is why Softed
              encourages its students to develop their own internal motivation
              and work together rather than against each other.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default Home;
