import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Home from './pages/home';
import About from './pages/about';
import Message from './pages/message';
import Contact from './pages/contact';
import Extra from './pages/extra';
import Analytics from './analytics';



function App() {

  return (
    <Router>
      <Analytics />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/message" element={<Message />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/extra" element={<Extra />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;